/* Component Dependencies */
var cardHeroTemplate = require('templates/cardHero.hbs');
var cardHeroTagpTemplate = require('templates/cardHero-tagp.hbs');
var AriesComponent = require('libs/aries-component');
require('libs/jquery.dotdotdot');
AriesComponent.create({
  type: 'cardHero',
  template: {
    'cardHero': cardHeroTemplate,
    'cardHero-tagp': cardHeroTagpTemplate
  },
  bindEvents: function() {
    var _self = this;
    _self.addBgClass();
    _self.$el.find('.t-ito-text').dotdotdot({
      watch: 'window'
    });
    _self.positionHeading();
    _self.callOnResize();
    
  },
  options: {
    'MIN_DESKTOP_FONT_SIZE': 90,
    headingOffset : 32
  },
  addBgClass:function(){
    var $el = this.$el.find('.js-background-shape');
    var nobg = $el.data('nobg');
    var sum = _.sum($el.val());
     if(sum === 0 || nobg){
      this.$el.find('.m-text-overlay').addClass('t-no-bg').removeClass('t-bg-program');
    }else{
      this.$el.find('.m-text-overlay').addClass('t-bg-program');
    }
  },
  /**
    * This function is used to call other functions on resize of the window
    */
  callOnResize:function(){
		var _self = this;
		$(window).on('resize orientationchange', _self, function(event){
      var _self = event.data;
      _self.positionHeading();
		});
	},
  /**
    * This function is used to display heading on ito
    */ 
  positionHeading : function() {
    var overlayContainer = this.$el.find('.m-text-overlay');
    var overlayHeight = overlayContainer.innerHeight();
    overlayContainer.find("h1, h2").css("bottom", overlayHeight - this.options.headingOffset+"px");
  }
});
