var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <section class=\"tile-card-hero tile-card-hero-tagp\">\n    <div class=\"l-image-container t-bg-program"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? lookupProperty(stack1,"mobileImageFile") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":46},"end":{"line":3,"column":97}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"image") : depth0),{"name":"with","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"caption") : depth0),{"name":"with","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":6},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "           \n"
    + ((stack1 = __default(require("../../../../../../core/helpers/if-cond.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"headline") : depth0),"or",((stack1 = (depth0 != null ? lookupProperty(depth0,"descriptionsList") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if-cond","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":6},"end":{"line":49,"column":18}}})) != null ? stack1 : "")
    + "    </div>\n  </section>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " l-mobile-image";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("../../../partials/responsiveImage.hbs"),depth0,{"name":"responsiveImage","hash":{"mobileQueryParam":((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"akamaiConversionParams"))) && lookupProperty(stack1,"mobileDownsize")),"tabletQueryParam":((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"akamaiConversionParams"))) && lookupProperty(stack1,"tabletDownsize")),"desktopQueryParam":((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"akamaiConversionParams"))) && lookupProperty(stack1,"desktopDownsize")),"largeDesktopQueryParam":((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"akamaiConversionParams"))) && lookupProperty(stack1,"largeDesktopDownsize")),"mobileFirst":false,"baseUrl":((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"baseUrl"))},"data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"text") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <p class=\"m-hero-caption t-font-xs t-bg-caption t-color-inverse l-padding-half\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"url") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":21,"column":19}}})) != null ? stack1 : "")
    + "          </p>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <a class=\"t-alt-link t-no-hover-link l-display-block\" href=\""
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0)) != null ? stack1 : "")
    + "\" title=\""
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"altTag") : depth0), depth0)) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "</a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <input type=\"hidden\" class=\"js-background-shape\" data-nobg=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"hideBackgroundShape") : depth0), depth0))
    + "\" value=\"["
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"descriptionsList") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + ", "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"headline") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + ", "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"callToAction") : depth0)) != null ? lookupProperty(stack1,"url") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "]\">  \n        <div class=\"m-text-overlay-tagp t-bg-program "
    + ((stack1 = __default(require("../../../../../../core/helpers/if-cond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"itoAlignment") : depth0),"eq","right",{"name":"if-cond","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":53},"end":{"line":28,"column":130}}})) != null ? stack1 : "")
    + "  "
    + ((stack1 = __default(require("../../../../../../core/helpers/if-cond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"hasMemberStyle") : depth0),"eq","true","and",(depth0 != null ? lookupProperty(depth0,"memberBgd") : depth0),{"name":"if-cond","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":28,"column":132},"end":{"line":28,"column":236}}})) != null ? stack1 : "")
    + "  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? lookupProperty(stack1,"mobileImageFile") : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":238},"end":{"line":28,"column":407}}})) != null ? stack1 : "")
    + "\"> \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"headline") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":31,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"subHeadline") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":34,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"shortSubHeadline") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":37,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"descriptionsList") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":12},"end":{"line":42,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"with").call(alias3,(depth0 != null ? lookupProperty(depth0,"callToAction") : depth0),{"name":"with","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":12},"end":{"line":47,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return " l-pos-right l-right-align ";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"memberBgd") : depth0), depth0))
    + " ";
},"17":function(container,depth0,helpers,partials,data) {
    return "  t-bg-program";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " l-mobile-overlay "
    + ((stack1 = __default(require("../../../../../../core/helpers/if-cond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"image") : depth0)) != null ? lookupProperty(stack1,"mobileImageAspectRatioName") : stack1),"eq","classic",{"name":"if-cond","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":28,"column":285},"end":{"line":28,"column":400}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    return " l-mobile-classic ";
},"22":function(container,depth0,helpers,partials,data) {
    return " l-mobile-square ";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + ((stack1 = __default(require("../../../../../../core/helpers/if-cond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"headerTag") : depth0),"eq","h1",{"name":"if-cond","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":14},"end":{"line":30,"column":147}}})) != null ? stack1 : "")
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"t-heading-text t-extend-h1-em t-color-inverse t-uppercase\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"headline") : depth0), depth0)) != null ? stack1 : "")
    + "</h1>";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"subHeaderTag") : depth0), depth0))
    + " class=\"t-subheading-text-desktop t-color-inverse t-uppercase\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"subHeadline") : depth0), depth0)) != null ? stack1 : "")
    + "</"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"subHeaderTag") : depth0), depth0))
    + "> \n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"subHeaderTag") : depth0), depth0))
    + " class=\"t-subheading-text t-color-inverse t-uppercase\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"shortSubHeadline") : depth0), depth0)) != null ? stack1 : "")
    + "</"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"subHeaderTag") : depth0), depth0))
    + ">\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"t-body-description t-color-inverse\">\n                    "
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"descriptionsList") : depth0),{"name":"each","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":40,"column":76}}})) != null ? stack1 : "")
    + "\n                </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"descriptionText") : depth0), depth0)) != null ? stack1 : "");
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"t-color-inverse\">\n"
    + ((stack1 = container.invokePartial(require("../../../partials/callToAction.hbs"),depth0,{"name":"callToAction","hash":{"cardCta":true,"ctaType":((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"ctaType"))},"data":data,"indent":"                  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "              </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"image") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":52,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});